import React, { useEffect, useState } from 'react';
import db from './firebase';
import { useParams } from 'react-router-dom';

function Redirect() {
  const { slug } = useParams();
  const [message, setMessage] = useState('');

  useEffect(() => {
    let query = db.collection('urls').where('slug', '==', slug);
    query.onSnapshot((data) => {
      if (data.empty) {
        setMessage('OPS! Something wrong.');
      } else {
        let finalData = data.docs[0].data();
        setMessage(`Welcome to ${finalData.url}`);
        window.location.href = finalData.url;
      }
    });
  }, [slug]);

  return (
    <div>
      <center>
        <p>{message}</p>
      </center>
    </div>
  );
}

export default Redirect;